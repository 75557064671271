.footer {
  transition: background-color 0.5s ease-in-out,
}

.footer-content {
  max-width: 90rem;
  margin: auto;
  display: flex;
  justify-content: space-between;
  padding: 70px 50px;
  scroll-snap-align: end;
}

.footer .contact, .footer .copyright, .footer .last-updated, .footer p {
  padding-right: 80px;
}

.footer-text {
  display: flex;
  flex-direction: row;
}

.footer .p1 {
  font-family: 'Acumin Pro Light';
  font-size: 12px;
  letter-spacing: 1.6px;
  color: #FFFFFF;
  opacity: 0.6;
  line-height: 12px;
  margin-bottom: 15px;
}

.footer .p2 {
  font-family: 'Emirates';
  font-size: 18px;
  color: #FFFFFF;
  line-height: 10px;
}

.contact-icons {
  height: 50px;
}

.contact-icons img {
  width: 50px;
  display: flex;
  translate: 0px 10px;
}