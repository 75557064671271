@font-face {
  font-family: 'Acumin Pro';
  src: url('./fonts/Acumin-RPro.otf') format('opentype');
}

@font-face {
  font-family: 'Acumin Pro Light';
  src: url('./fonts/Acumin-LPro.ttf') format('truetype');
}

@font-face {
  font-family: 'Acumin Pro Semibold';
  src: url('./fonts/Acumin-SPro.ttf') format('truetype');
}

@font-face {
  font-family: 'Acumin Pro Bold';
  src: url('./fonts/Acumin-BPro.ttf') format('truetype');
}

@font-face {
  font-family: 'Droid Serif';
  src: url('./fonts/DroidSerif-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Emirates';
  src: url('./fonts/Emirates Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Moret';
  src: url('./fonts/Moret-Regular.otf') format('opentype');
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('./fonts/SF-Pro-Display-Regular.otf') format('opentype');
}

h1, h2, h3, h4, h5, h6, b {
  font-weight: normal;
}

body {
  background-color: var(--speakeasy-bg);
}

:root {
  --trivio-bg: #1A1828;
  --trivio-footer: #1E1B2F;
  --speakeasy-bg: #181818;
  --speakeasy-footer: #1C1C1C;
  --caravan-bg: #A8A5A2;
  --caravan-footer: #A09C99;
  --text-color: #FFFFFF;
}
