.about-me h3 {
  font-family: 'Emirates';
  color: var(--text-color);
  font-size: 24px;
}

.about-me p {
  color: var(--text-color);
  font-size: 18px;
  opacity: 0.8;
  line-height: 30px;
}

.about-me .content {
  display: flex;
  max-width: 90rem;
  margin: auto;
  padding: 100px 50px;
  justify-content: space-between;
}

.about-me .content .lhs {
  max-width: 600px;
}

.about-me .content img {
  height: 645px;
  margin-left: 100px;
}