.homepage p {
  color: var(--text-color);
  font-size: 18px;
}

.homepage h1 {
  font-family: 'Emirates';
  font-size: 40px;
  margin: 0;
}

.homepage h2 {
  font-family: 'Emirates';
  font-size: 24px;
  margin: 0;
  color: var(--text-color);
}

.homepage {
  display: flex;
  flex-direction: column;
  height: 100vh;
  transition: background-color 0.5s ease-in-out;
}

.homepage .eyebrow {
  font-size: 14px;
  letter-spacing: 2px;
}

.homepage-container {
  flex: 1;
  overflow-y: scroll; 
  scroll-snap-type: y mandatory;
}

.featured-projects {
  max-width: 80rem;
  padding: 0px 50px;
  margin: auto;
  padding-top: 60px;
  padding-bottom: 100px;
}

/* Nav Bar */
.nav-bar-container {
  width: 100%;
}

/* Trivio */
.trivio-feature {
  display: flex;
  flex-wrap: no-wrap;
  flex-direction: row;
  background-image: linear-gradient(#1F1E35, #1B1A34);
  cursor: pointer;
  height: 65vh;
  scroll-snap-align: start;
  transition: opacity 0.5s ease-in-out, border-top 0.5s ease-in-out;
  overflow: hidden;
}

.trivio-feature .left-container {
  display: flex;
  flex: 1;
  padding-top: 50px;
  flex-direction: column;
  justify-content: space-between;
}

.cover-titles {
  color: var(--text-color);
  padding-left: 60px;
  max-width: 350px;
}

.eyebrow {
  color: var(--text-color);
  font-family: 'Acumin Pro Light';
  font-size: 14px;
  letter-spacing: 2.5px;
  opacity: 0.6;
  margin: 0px;
  margin-bottom: 10px;
}

.trivio-subtitle {
  padding: 15px 0px;
}

.cover-titles b,
.cover-titles span {
  font-family: 'Acumin Pro Light';
  font-size: 18px;
  color: var(--text-color);
  opacity: 0.8;
}

.read-cta img {
  width: 16.5px;
  height: 8px;
  margin: 0;
  translate: 5px 0px;
}

.read-cta {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.cover-titles .read-cta p {
  font-family: 'Acumin Pro';
  opacity: 1;
  margin: 5px 0px;
}

.trivio-feature .mobile-previews {
  padding-left: 60px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

.trivio-feature .mobile-previews img {
  width: 18vh;
  margin-right: 20px;
  margin-top: 20px;
}

.trivio-feature .homescreen-annotations {
  padding-bottom: 60px;
  padding-left: 20px;
}

.trivio-feature .homescreen-annotations p {
  color: var(--text-color);
  margin: 0px;
  font-size: 20px;
  opacity: 0.6;
  margin-top: 5px;
  width: 175px;
}

.desktop-previews {
  display: flex;
  position: inherit;
  flex: 1;
  overflow: hidden;
  padding-top: 50px;
}

.desktop-previews .lhs {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  translate: 80px 0px;
}

.desktop-previews .mobile-screens {
  display: flex;
}

.desktop-previews .mobile-screens img {
  width: 10vh;
}

.desktop-previews .homescreen-annotations {
  margin-top: 20px;
  text-align: right;
}

.desktop-previews .notebook-mockup {
  translate: 0px -15px;
  width: 470px;
  height: 450px;
}

/* Speakeasy */
.speakeasy-feature {
  display: flex;
  align-items: flex-end;
  background-image: linear-gradient(#201F1F, #1D1D1D);
  color: var(--text-color);
  justify-content: space-between;
  cursor: pointer;
  scroll-snap-align: start;
  transition: opacity 0.5s ease-in-out, border-top 0.5s ease-in-out;
  height: 65vh;
}

.speakeasy-feature p {
  color: var(--text-color);
}

.speakeasy-feature .screenshots {
  height: 100%;
  margin-right: 60px;
}

.speakeasy-feature .speakeasy-titles {
  padding: 60px;
}

.speakeasy-feature .speakeasy-subtitle {
  max-width: 380px;
}

.speakeasy-titles h1 {
  margin-top: -5px;
}

.speakeasy-subtitle {
  color: var(--text-color);
  margin: 0;
  max-width: 450px;
  font-size: 20px;
  padding: 15px 0px;
  opacity: 0.8;
}

.speakeasy-titles .read-cta p {
  font-family: 'Acumin Pro';
  opacity: 1;
  margin: 5px 0px;
}

/* Caravan */
.caravan-feature {
  display: flex;
  height: 65vh;
  background-size: cover;
  align-items: center;
  scroll-snap-align: start;
  transition: opacity 0.5s ease-in-out, border-top 0.5s ease-in-out;
  margin-bottom: 100px;
}

.caravan-feature .black-gradient {
  height: 100%;
  width: 100%;
  background-color: black;
}

.caravan-feature h1 {
  margin-top: -10px;
  color: var(--text-color);
}

.caravan-feature img {
  height: 80%;
  padding: 70px 70px;
}

.caravan-feature .caravan-titles {
  display: flex;
  flex-direction: column;
  max-width: 550px;
  height: 100%;
  justify-content: flex-end;
  padding-bottom: 150px;
}

.caravan-feature .caravan-titles p {
  margin: 10px 0px;
}

.caravan-feature .info-cta {
  display: flex;
  align-items: center;
}

.caravan-feature .info-cta p {
  opacity: 1;
  font-family: 'Acumin Pro';
  margin: 5px 0px;
}

.caravan-feature .info-cta .circular-separator {
  width: 6px;
  height: 6px;
  border-radius: 3px;
  background-color: white;
  opacity: 0.6;
  margin: 0px 10px;
  translate: 0px -2px;
}
