.speakeasy-case-study h1, 
.speakeasy-case-study h2, 
.speakeasy-case-study h3, 
.speakeasy-case-study h4, 
.speakeasy-case-study p, 
.speakeasy-case-study .eyebrow {
  color: var(--text-color);
  margin: 0;
}

.speakeasy-case-study h1 {
  font-family: 'Emirates';
  font-size: 40px;
}

.speakeasy-case-study h2 {
  font-family: 'Emirates';
  font-size: 24px;
}

.speakeasy-case-study h3 {
  font-family: 'Emirates';
  font-size: 34px;
}

.speakeasy-case-study h4 {
  font-family: 'Emirates';
  font-size: 28px;
}

.speakeasy-case-study p {
  font-size: 20px;
  opacity: 0.8;
  line-height: 30px;
}

.speakeasy-case-study .eyebrow {
  font-size: 14px;
  letter-spacing: 2px;
}

/* Cover */
.speakeasy-cover {
  background-image: linear-gradient(#201F1F, #1D1D1D);
  border-bottom: 6px solid #232323;
}

.speakeasy-cover .wrapper {
  max-width: 90rem;
  padding: 0px 50px;
  margin: auto;
  display: flex;
  flex-direction: row;
  height: 800px;
  justify-content: space-between;
}

.speakeasy-cover .lhs-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.speakeasy-cover .lhs-container h1,
.speakeasy-cover .lhs-container p {
  color: var(--text-color);
  margin: 0px;
  margin-bottom: 10px;
}

.speakeasy-cover .my-name {
  margin-top: 50px;
}

.speakeasy-cover .lhs-container .subtitle {
  font-size: 22px;
  color: var(--text-color);
  opacity: 0.8;
  width: 65%;
  line-height: 32px;
  padding-bottom: 40px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.speakeasy-cover .lhs-container h2 {
  margin-top: 50px;
}

.speakeasy-cover .cover-details {
  display: flex;
  text-align: left;
  margin-top: 20px;
}

.speakeasy-cover .cover-details .eyebrow {
  font-size: 14px;
  letter-spacing: 2px;
}

.speakeasy-cover .cover-details .subtext {
  color: var(--text-color);
  font-size: 22px;
}

.cover-details .detail {
  margin-right: 40px;
}

.speakeasy-cover .screenshots {
  height: 100%;
}

/* Background */
.background {
  max-width: 90rem;
  padding: 115px 50px;
  margin: auto;
}

.background h3 {
  margin-right: 100px;
}

.background .text {
  display: flex;
  color: var(--text-color);
  justify-content: space-between;
  align-items: flex-end;
}

.background .text p {
  width: 56rem;
  color: var(--text-color);
}

.background-screenshots {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #0D0D0D;
  border-top: 6px solid #232323;
}

.background-screenshots img {
  padding: 50px;
  max-width: 80%;
}

/* Thought Process */
.thought-process {
  border-top: 6px solid #232323;
  border-bottom: 6px solid #232323;
}

.thought-process .margin-wrapper {
  display: flex;
  max-width: 90rem;
  padding: 115px 50px;
  margin: auto;
}

.thought-process .blurb {
  margin-right: 150px;
}

.thought-process .blurb h4 {
  margin-bottom: 15px;
}

/* Iteration */
.iteration {
  max-width: 90rem;
  padding: 115px 50px;
  margin: auto;
}

.iteration .intro {
  display: flex;
  align-items: flex-end;
  padding-bottom: 100px;
  margin-bottom: 100px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.iteration .intro img {
  height: 600px;
}

.iteration .intro .text {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 600px;
}

.iteration .intro img {
  margin-right: 70px;
}

.iteration .side-scroll-wireframes {
  display: flex;
  overflow-x: auto;
}

.iteration .side-scroll-wireframes::-webkit-scrollbar {
  display: none;
}

.side-scroll-wireframes .version {
  display: flex;
  align-items: flex-end;
  margin-right: 40px;
}

.side-scroll-wireframes .version .text {
  width: 350px;
  margin-left: 30px;
}

.side-scroll-wireframes .version img {
  height: 600px;
}

.side-scroll-wireframes .version .text p {
  margin-top: 10px;
}

/* Design System */
.design-system {
  border-top: 6px solid #232323;
  border-bottom: 6px solid #232323;
}

.design-system .wrapper {
  max-width: 90rem;
  padding: 115px 50px;
  margin: auto;  
}

.design-system .design-system-cards {
  display: flex;
  margin-top: 50px;
}

.design-system-card p {
  font-size: 18px;
  line-height: 22px;
}

.design-system-card .eyebrow {
  font-size: 14px;
}

.design-system-card h4 {
  margin-bottom: 50px;
}

.design-system-card {
  background-image: linear-gradient(#292929, #242424);
  padding: 40px;
  display: flex;
  flex-direction: column;
  width: 30rem;
}

.design-system-card .contents {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 800px;
}

.design-system-card .featured-color {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.design-system-card .featured-color .text {
  max-width: 175px;
}

.featured-color .color-card {
  width: 120px;
  background-color: #333333;
}

.color-card .color-charcoal {
  width: 120px;
  height: 100px;
  background-color: #151515;
}

.color-card .color-white {
  width: 120px;
  height: 100px;
  background-color: #FFFFFF;
}

.color-card .color-hot-pink {
  width: 120px;
  height: 100px;
  background-color: #FF007D;
}

.color-card .titles {
  padding: 12px;
}

.color-card .title {
  font-family: 'Acumin Pro Semibold';
  opacity: 1;
}

.featured-color .eyebrow {
  margin-bottom: 5px;
}

.design-system-card .headers {
  display: flex;
  flex-direction: column;
}

.design-system-card .headers img {
  width: 100%;
  margin-bottom: 20px;
}

.design-system-card .messaging img {
  width: 100%;
}

.design-system-card .messaging p {
  margin-top: 20px;
}

.design-system-card .heading-previews {
  display: flex;
  justify-content: space-between;
  background-color: #212121;
  padding: 30px 40px;
}

.design-system-card .heading1,
.design-system-card .heading2,
.design-system-card .heading3 {
  font-family: 'Moret';
  opacity: 1;
  margin-bottom: 5px;
}

.typography-contents .eyebrow {
  translate: 0px 10px;
}

.design-system-card .font-stack {
  margin-bottom: 20px;
}

.design-system-card .heading1 {
  font-size: 50px;
  line-height: 50px;
}

.design-system-card .heading2 {
  font-size: 30px;
  line-height: 30px;
}

.design-system-card .heading3 {
  font-size: 22px;
  line-height: 22px;
}

.design-system-card .heading-description,
.design-system-card .body-description {
  padding: 30px 40px;
}

.design-system-card .heading-description .title,
.design-system-card .body-description .title {
  font-family: 'Acumin Pro Semibold';
  opacity: 1;
  margin-bottom: 5px;
}

.design-system-card .typography-contents {
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  /* height: 800px; */
  width: 29rem;
}

.design-system-card .body-previews {
  display: flex;
  justify-content: space-between;
  padding: 30px 40px;
  background-color: #212121;
}

.body-previews .eyebrow {
  translate: 0px -3px;
}

.body-previews .font-stack {
  text-align: right;
}

.body-previews .font-stack .body1 {
  font-family: 'SF Pro Display';
  opacity: 1;
  font-size: 18px;
}

.body-previews .font-stack .body2 {
  font-family: 'SF Pro Display';
  opacity: 1;
  font-size: 14px;
}

.final-tour {
  max-width: 90rem;
  padding: 115px 50px;
  margin: auto;
}

.final-tour .row-1,
.final-tour .row-2,
.final-tour .row-3 {
  display: flex;
  margin-top: 50px;
}

.final-tour .row-1 img {
  height: 700px;
}

.final-tour .row-1 .panel-1,
.final-tour .row-2 .panel-1,
.final-tour .row-3 .panel-1 {
  padding: 50px;
  flex: 6;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000000;
}

.final-tour .row-1 .panel-2,
.final-tour .row-2 .panel-2 {
  padding: 50px;
  flex: 4;
  background-color: #424242;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 45px;
}

.final-tour .row-2 img {
  height: 550px;
}

.final-tour .row-3 img {
  height: 650px;
}

/* Reflections */
.speakeasy-case-study .reflections {
  border-top: 6px solid #232323;
}

.speakeasy-case-study .reflections .text {
  display: flex;
  justify-content: space-between;
  max-width: 90rem;
  padding: 115px 50px;
  margin: auto;
}

.speakeasy-case-study .reflections .text h3 {
  margin-right: 50px;
}

.speakeasy-case-study .reflections .text p {
  max-width: 700px;
}