.navbar {
  margin: auto;
  transition: background-color 0.5s ease-in-out;
  padding: 0px 50px;
}

.navbar .wrapper {
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  z-index: 100;
  padding: 50px 0px;
}

.my-name {
  font-family: 'Emirates';
  font-size: 26px;
  text-decoration: none;
  color: var(--text-color);
}

.nav-links {
  display: flex;
  gap: 20px;
}

.nav-link {
  font-family: 'Acumin Pro Light';
  font-size: 16px;
  text-decoration: none;
  color: var(--text-color);
  margin-left: 120px;
  transition: opacity 0.5s ease-in-out;
}

.nav-link:hover {
  opacity: 1;
}

.nav-link p {
  margin: 0;
  font-size: 16px;
}
