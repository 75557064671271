.trivio h1, 
.trivio h2, 
.trivio h3, 
.trivio h4 {
  font-family: 'Emirates';
  color: var(--text-color);
  margin: 10px 0px;
}

.trivio h1 {
  font-size: 50px;
}

.trivio h2 {
  font-size: 40px;
}

.trivio h3 {
  font-size: 34px;
}

.trivio h4 {
  font-size: 24px;
}

.trivio h5 {
  color: var(--text-color);
  font-family: 'Acumin Pro Light';
  font-size: 18px;
  letter-spacing: 1.2px;
  opacity: 0.6;
  margin: 0px;
  margin-bottom: 5px;
  
}

.trivio h6 {
  color: var(--text-color);
  font-family: 'Acumin Pro';
  font-size: 24px;
  letter-spacing: -0.3px;
  margin: 10px 0px;
}

.trivio {
  background-color: var(--trivio-bg);
}

.trivio p {
  font-size: 18px;
  color: white;
  opacity: 0.8;
  margin: 0px;
}

/* Case Study Cover Page */
.trivio-cover {
  overflow: hidden;
  background-image: linear-gradient(#1F1E35, #1B1A34);
  border-bottom: 6px solid #232136;
}

.trivio-cover-content {
  height: 650px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 90rem;
  padding: 0px 50px;
  margin: auto;
  align-items: flex-end;
}

.mockup-container img {
  height: 600px;
  translate: 0px 5px;
  padding: 0px 50px;
}

.cover-details {
  text-align: right;
  translate: 0px 5px;
}

.details-entry {
  padding-bottom: 15px;
}

.trivio-cover-content .title-subtitle {
  max-width: 450px;
}

.trivio-cover-content .title-subtitle p {
  font-family: 'Acumin Pro Light';
  font-size: 18px;
  letter-spacing: -0.1px;
  color: white;
}

.trivio-cover-content .title-subtitle b,
.trivio-cover-content .title-subtitle span {
  font-family: 'Acumin Pro Light';
  font-size: 18px;
  color: white;
}

.trivio-cover-content .title-subtitle, .cover-details {
  padding-bottom: 60px;
}

.trivio-cover-content .cover-details .p1 {
  font-family: 'Acumin Pro Light';
  font-size: 12px;
  letter-spacing: 0.85px;
  color: white;
  opacity: 0.4;
  margin: 0;
  margin-top: 10px;
}

.cover-details .p2 {
  font-family: 'Acumin Pro Light';
  font-size: 18px;
  color: white;
  margin: 0;
}

/* Introduction */
.trivio-introduction-wrapper {
  border-bottom: 6px solid #232136;
}

.trivio-introduction {
  max-width: 90rem;
  margin: auto;
  display: flex;
  justify-content: space-between;
  padding: 80px 50px;
}

.trivio-introduction h2 {
  min-width: 40%;
}

p {
  font-family: 'Acumin Pro Light';
  font-size: 16px;
  line-height: 24px;
}

b {
  font-family: 'Acumin Pro Semibold';
}

.trivio-introduction .introduction-contents img {
  width: 100%;
  padding-top: 40px;
  padding-bottom: 20px;
}

/* At a Glance */
.trivio .at-a-glance {
  background-color: #12111C;
  position: relative;
  overflow: hidden;
  border-bottom: 6px solid #232136;
}

.trivio .at-a-glance .wrapper {
  margin: auto;
  overflow: hidden;
}

.trivio .at-a-glance .titles {
  margin-top: 0;
  background: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0));
  z-index: 100;
  width: 100%;
  position: absolute;
}

.trivio .at-a-glance .titles-wrapper {
  margin: auto;
  width: 90rem;
  padding: 50px 50px;
}

.trivio .at-a-glance img {
  display: block;
  margin: auto;
  padding: 0px 50px;
  width: 90rem;
}

.trivio .at-a-glance .bottom-gradient {
  bottom: 0;
  /* z-index: 100; */
  position: absolute;
  width: 100%;
  height: 150px;
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8));
}

/* Act 1, Chapter 1 Introduction */
.act1-chapter1 {
  max-width: 90rem;
  margin: auto;
  display: flex;
  flex-direction: row;
  padding: 80px 50px;
  align-items: flex-end;
}

.act1-chapter1 .act1-title-subtitle {
  min-width: 40%;
}

.act1-chapter1 .act1-title-subtitle p {
  font-family: 'Acumin Pro Light';
  font-size: 20px;
  letter-spacing: -0.2px;
}

/* Act 1, Chapter 1 Gameplay Wireframes */
.act1-chapter1-wireframe-gallery {
  background-color: #12111C;
}

.act1-chapter1-wireframe-gallery .gallery-content-wrapper {
  max-width: 90rem;
  margin: auto;
  padding: 80px 50px;
}

.act1-chapter1-wireframe-gallery .gallery-content-wrapper .title-subtitle {
  margin-bottom: 80px;
}

.act1-chapter1-wireframe-gallery .gallery-content-wrapper .title-subtitle p {
  font-family: 'Acumin Pro Light';
  font-size: 20px;
  opacity: 0.8;
}

.act1-chapter1-wireframe-gallery .interactive-wireframes {
  display: flex;
}

.act1-chapter1-wireframe-gallery .interactive-wireframes img {
  height: 560px;
}

.act1-chapter1-wireframe-gallery .interactive-wireframes .unfocused {
  opacity: 0.4;
  padding-right: 20px;
  transition: opacity 0.5s ease-in-out;
}

.act1-chapter1-wireframe-gallery .interactive-wireframes .unfocused:hover {
  opacity: 1;
  cursor: pointer;
}

.interactive-wireframes .wireframe-annotations {
  padding-right: 50px;
}

.interactive-wireframes .wireframe-annotations h2 {
  font-family: 'Acumin Pro Light';
  font-size: 18px;
  padding-left: 10px;
  letter-spacing: 1.2px;
  margin-top: 0px;
}

.interactive-wireframes .wireframe-annotations p {
  padding-left: 10px;
  padding-right: 20px;
  max-width: 400px;
}

/* Act 1, Chapter 2 Introduction */
.act1-chapter2 .chapter-content-wrapper {
  max-width: 90rem;
  margin: auto;
  padding: 80px 50px;
  display: flex;
}

.chapter-content-wrapper .title-subtitle {
  min-width: 40%;
  translate: 0px 15px;
}

.chapter-content-wrapper .title-subtitle h2 {
  font-family: 'Acumin Pro Light';
  font-size: 18px;
  letter-spacing: 1.2px;
  margin-bottom: 15px;
}

.chapter-content-wrapper .title-subtitle h3 {
  font-family: 'Acumin Pro';
  font-size: 24px;
  letter-spacing: -0.3px;
  line-height: 20px;
}

/* Act 1, Chapter 2 Competitive Audit */
.act1-chapter2-audit {
  padding: 80px 50px;
  background-color: #22212E;
}

.act1-chapter2-audit .audit-wrapper {
  max-width: 90rem;
  margin: auto;
}

.act1-chapter2-audit .audit-wrapper .title-subtitle {
  margin-bottom: 60px;
}

.act1-chapter2-audit .audit-wrapper .title-subtitle h1 {
  font-family: 'Emirates';
  font-size: 24px;
  margin-bottom: 5px;
}

.act1-chapter2-audit .audit-wrapper .title-subtitle p {
  font-family: 'Acumin Pro Light';
  font-size: 20px;
  letter-spacing: -0.2px;
}

.act1-chapter2-audit .audit-wrapper .audit-contents {
  display: flex;
  flex-direction: row;
}

.act1-chapter2-audit .audit-wrapper .audit-contents .mobile-screenshots img {
  padding-right: 15px;
  max-width: 270px;
}

.act1-chapter2-audit .audit-wrapper .audit-contents .unfocused {
  opacity: 0.3;
  transition: opacity 0.5s ease-in-out;
}

.act1-chapter2-audit .audit-wrapper .audit-contents .unfocused:hover {
  opacity: 0.5;
  cursor: pointer;
}

.act1-chapter2-audit .audit-wrapper .audit-contents .mobile-screenshots {
  min-width: 65%;
}

.act1-chapter2-audit .audit-contents .thumbnails {
  display: flex;
  flex-direction: column;
  width: 185px;
  margin-right: 20px;
}

.act1-chapter2-audit .thumbnails .unfocused {
  opacity: 0.3;
  transition: opacity 0.5s ease-in-out;
}

.act1-chapter2-audit .audit-contents .thumbnails img {
  width: 185px;
  margin-bottom: 20px;
  border-radius: 5px;
}

.act1-chapter2-audit .audit-contents .desktop-screenshots {
  display: flex;
  justify-content: space-between;
}  

.act1-chapter2-audit .desktop-screenshots .main-slide {
  margin-right: 139px;
}  

.act1-chapter2-audit .desktop-screenshots .main-slide img {
  height: 565px;
  width: 592px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, .05);
}  

.audit-contents .audit-text-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 20px;
}

.audit-contents .audit-text-wrapper .audit-competitor-info {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.audit-contents .audit-text-wrapper .audit-competitor-info img {
  height: 19px;
  width: 19px;
}

.audit-contents .audit-text-wrapper .audit-competitor-info h2 {
  font-family: 'Acumin Pro Light';
  font-size: 20px;
  letter-spacing: 1.2px;
  padding: 0px 10px;
  translate: 0px 2px;
  width: 160px;
}

.audit-contents .audit-text-wrapper .audit-competitor-info .enabled-chevron,
.audit-contents .audit-text-wrapper .audit-competitor-info .disabled-chevron {
  background-color: transparent;
  height: 15px;
  padding: 6px 4px;
  translate: 0px -1px;
  transition: background-color 0.15s ease-in-out;
}

.audit-contents .audit-text-wrapper .audit-competitor-info .disabled-chevron {
  opacity: 0.3;
}

.audit-contents .audit-text-wrapper .audit-competitor-info .disabled-chevron:hover {
  cursor: not-allowed;
}

.audit-contents .audit-text-wrapper .audit-competitor-info .enabled-chevron:hover {
  background-color: #32303F;
  border-radius: 3px;
  cursor: pointer;
}

.audit-contents .audit-text-wrapper h1 {
  font-family: 'Emirates';
  font-size: 34px;
  margin-top: 10px;
}

/* Act 1, Chapter 2 Audit Takeaways */
.act1-chapter2-takeaways {
  border-bottom: 1px solid #322F53;
}

.act1-chapter2-takeaways .takeaways-wrapper {
  max-width: 90rem;
  margin: auto;
  padding: 80px 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.act1-chapter2-takeaways .takeaways-wrapper .takeaways-text h1 {
  font-family: 'Emirates';
  font-size: 20px;
}

.act1-chapter2-takeaways .takeaways-wrapper .list-image-focused,
.act1-chapter2-takeaways .takeaways-wrapper .list-image-unfocused {
  height: 390px;
}

.act1-chapter2-takeaways .takeaways-wrapper .list-image-unfocused {
  opacity: 0.4;
  transition: opacity 0.5s ease-in-out;
}

.act1-chapter2-takeaways .takeaways-wrapper .list-image-focused.hp {
  box-shadow: 5px 0px 10px rgba(0, 0, 0, .05);
}

.act1-chapter2-takeaways .takeaways-wrapper .list-image-focused.sb {
  box-shadow: -5px 0px 10px rgba(0, 0, 0, .05);
}

.act1-chapter2-takeaways .takeaways-wrapper .list-image-unfocused:hover {
  opacity: 0.6;
  cursor: pointer;
}

.act1-chapter2-takeaways .takeaways-wrapper .takeaways-text {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.takeaways-wrapper .takeaways-text .trivio-branding-info {
  display: flex;
  flex-direction: row;
}

.takeaways-text .takeaways-text-content .trivio-branding-info h2 {
  line-height: 10px;
  font-family: 'Acumin Pro Light';
  font-size: 20px;
  letter-spacing: 1.2px;
  translate: 0px 3px;
  padding-left: 10px;
}

.takeaways-text .takeaways-text-content .trivio-page-selector {
  display: flex;
  flex-direction: row;
}

.takeaways-text .takeaways-text-content .trivio-page-selector img {
  padding: 0px 20px;
}

.takeaways-text .takeaways-text-content .trivio-page-selector .unfocused {
  opacity: 0.4;
  transition: opacity 0.5s ease-in-out;
}

.takeaways-text .takeaways-text-content .trivio-page-selector .unfocused:hover {
  opacity: 0.7;
  cursor: pointer;
}

.takeaways-text .takeaways-text-content .trivio-page-selector h1 {
  font-family: 'Emirates';
  font-size: 30px;
  color: white;
  line-height: 10px; 
}

.takeaways-text .takeaways-text-content {
  max-width: 40rem;
}

/* Act 1, Chapter 3 Introduction */
.act1-chapter3-section1 {
  max-width: 90rem;
  margin: auto;
  padding: 80px 50px;
  display: flex;
  flex-direction: row;
}

.act1-chapter3-section1 .miss-tina-visuals {
  min-width: 40%;
  margin-right: 20px;
}

.act1-chapter3-section1 .miss-tina-visuals img {
  height: 347px;
}

.act1-chapter3-section1 .miss-tina-visuals .shadowed {
  border-radius: 5px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, .05);
}

.act1-chapter3-section1 .section1-text-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.act1-chapter3-section1 .section1-text-wrapper .section1-text-headings h2 {
  font-family: 'Acumin Pro Light';
  font-size: 18px;
  letter-spacing: 1.2px;
  color: #2E2E2E;
  line-height: 0px;
}

.act1-chapter3-section1 .section1-text-wrapper .section1-text-headings h3 {
  font-family: 'Acumin Pro';
  font-size: 24px;
  letter-spacing: -0.3px;
  color: #2C2C2C;
  line-height: 20px;
}

/* Act 1, Chapter 3 Slideshow */
.act1-chapter3-slideshow {
  background-color: #12111C;
}

.act1-chapter3-slideshow .slideshow-content-wrapper {
  max-width: 90rem;
  margin: auto;
  padding: 80px 50px;
}

.act1-chapter3-slideshow .slideshow-content-wrapper .slideshow-titles {
  text-align: center;
}

.act1-chapter3-slideshow .slideshow-content-wrapper .slideshow-titles h1 {
  font-family: 'Emirates';
  font-size: 20px;
  color: white;
  margin-bottom: -10px;
}

.act1-chapter3-slideshow .slideshow-content-wrapper .slideshow-titles p {
  font-family: 'Acumin Pro Light';
  font-size: 20px;
  color: white;
  opacity: 0.8;
}

.act1-chapter3-slideshow .slideshow-content-wrapper .slideshow-contents {
  display: flex;
  flex-direction: row;
  padding: 40px 0px;
  justify-content: center;
}

.act1-chapter3-slideshow .slideshow-contents .lefthand-annotations,
.act1-chapter3-slideshow .slideshow-contents .righthand-annotations {
  width: 250px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.act1-chapter3-slideshow .slideshow-contents .lefthand-annotations {
  text-align: right;
}

.act1-chapter3-slideshow .slideshow-contents .righthand-annotations {
  text-align: left;
}

.act1-chapter3-slideshow .lefthand-annotations p,
.act1-chapter3-slideshow .righthand-annotations p {
  color: white;
}

.act1-chapter3-slideshow .slideshow-contents .current-slide-mockup img {
  height: 580px;
}

.act1-chapter3-slideshow .slideshow-contents .annotation {
  display: flex;
  justify-content: flex-end;
  align-items: top;
}

.act1-chapter3-slideshow .slideshow-contents .annotation p {
  margin-right: 10px;
  margin-left: 10px;
  margin-top: 0px;
}

.act1-chapter3-slideshow .vertical-annotation-content img {
  margin-left: 10px;
  width: 60px;
}

.act1-chapter3-slideshow .vertical-annotation-content.lhs img {
  margin-right: 10px;
  width: 60px;
}

.act1-chapter3-slideshow .slideshow-control-panel {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #12111C;
  padding-bottom: 80px;
}

.act1-chapter3-slideshow .slideshow-control-panel p {
  color: white;
}

.act1-chapter3-slideshow .previous-arrow,
.act1-chapter3-slideshow .next-arrow {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 38%;
}

.act1-chapter3-slideshow .previous-arrow {
  justify-content: flex-end;
  translate: -70px 0px;
}

.act1-chapter3-slideshow .next-arrow {
  translate: -8px 0px;
}

.act1-chapter3-slideshow .previous-arrow img {
  width: 60px;
  padding-left: 20px;
}

.act1-chapter3-slideshow .next-arrow img {
  width: 60px;
  padding-right: 20px;
}

.act1-chapter3-slideshow .arrow-labels .p1 {
  font-family: 'Acumin Pro Light';
}

.act1-chapter3-slideshow .arrow-labels p {
  opacity: .4;
  font-size: 20px;
  margin: 0;
  transition: opacity 0.5s ease-in-out;
}

.act1-chapter3-slideshow .previous-arrow .arrow-labels {
  text-align: right;
}

.act1-chapter3-slideshow .next-arrow .arrow-labels {
  translate: 80px 0px;
}

.act1-chapter3-slideshow .previous-arrow .stacked-arrow-buttons,
.act1-chapter3-slideshow .next-arrow .stacked-arrow-buttons {
  position: relative;
  translate: 0px -30px;
}

.act1-chapter3-slideshow .previous-arrow .stacked-arrow-buttons img,
.act1-chapter3-slideshow .next-arrow .stacked-arrow-buttons img {
  position: absolute;
  top: 0;
  left: 0;
  transition: opacity 0.5s ease-in-out;
}

.act1-chapter3-slideshow .previous-arrow .stacked-arrow-buttons img:last-child,
.act1-chapter3-slideshow .next-arrow .stacked-arrow-buttons img:last-child {
  opacity: 0;
}

.act1-chapter3-slideshow .previous-arrow:hover img:first-child,
.act1-chapter3-slideshow .next-arrow:hover img:first-child {
  opacity: 0;
  cursor: pointer;
}

.act1-chapter3-slideshow .previous-arrow:hover .arrow-labels p,
.act1-chapter3-slideshow .next-arrow:hover .arrow-labels p {
  opacity: 1;
  cursor: pointer;
}

.act1-chapter3-slideshow .previous-arrow:hover img:last-child,
.act1-chapter3-slideshow .next-arrow:hover img:last-child {
  opacity: 1;
  cursor: pointer;
}

.act1-chapter3-slideshow .current-slide-labels p {
  text-align: center;
  font-size: 20px;
  margin: 0;
}

/* Act 1, Chapter 3 Trivio by the Numbers */
.act1-chapter3-the-numbers {
  max-width: 90rem;
  margin: auto;
  padding: 80px 50px;
}

.act1-chapter3-the-numbers .title-subtitle h1 {
  font-family: 'Emirates';
  font-size: 20px;
  color: white;
  margin: 0;
}

.act1-chapter3-the-numbers .title-subtitle p {
  font-family: 'Acumin Pro Light';
  font-size: 20px;
  letter-spacing: -0.2px;
}

.act1-chapter3-the-numbers .primary-metrics {
  display: flex;
  margin-top: 85px;
}

.act1-chapter3-the-numbers .primary-metrics h1 {
  padding-right: 10px;
}

.act1-chapter3-the-numbers .primary-metrics .p1 {
  font-family: 'Acumin Pro';
  font-size: 20px;
}

.act1-chapter3-the-numbers .primary-metrics p {
  font-family: 'Acumin Pro Light';
  font-size: 16px;
  margin: 0;
}

.act1-chapter3-the-numbers .primary-metrics .metric {
  max-width: 300px;
  padding-right: 130px;
}

.act1-chapter3-the-numbers .primary-metrics .title {
  align-items: baseline;
}

.act1-chapter3-the-numbers .secondary-metrics {
  display: flex;
  justify-content: space-between;
  margin-top: 90px;
}

.act1-chapter3-the-numbers .secondary-metrics .session-data-tiles {
  display: flex;
  background-color: #1E1C34;
  height: 188px;
  border-radius: 5px;
  border: 1px solid #322F53;
  margin-right: 50px;
}

.act1-chapter3-the-numbers .secondary-metrics .tile {
  padding-right: 60px;
  padding-top: 60px;
  padding-left: 30px;
}

.act1-chapter3-the-numbers .secondary-metrics .tile h1 {
  font-family: 'Emirates';
  font-size: 50px;
  color: white;
  margin: 0;
  translate: 0px 10px;
}

.act1-chapter3-the-numbers .secondary-metrics .tile h2 {
  font-family: 'Acumin Pro Light';
  font-size: 15px;
  letter-spacing: 1px;
  margin: 0;
  translate: 0px 20px;
}

.act1-chapter3-the-numbers .secondary-metrics .set-makeup-graphic {
  display: flex;
  justify-content: flex-start;
  width: 600px;
}

.act1-chapter3-the-numbers .set-makeup-graphic .set-makeup-text {
  padding-left: 20px;
  display: flex;
  flex-direction: column;
}

.act1-chapter3-the-numbers .set-makeup-graphic .set-makeup-text h2 {
  font-family: 'Acumin Pro Light';
  font-size: 16px;
  letter-spacing: 1.2px;
  margin: 0;
}

.act1-chapter3-the-numbers .set-makeup-graphic .set-makeup-text h3 {
  font-family: 'Acumin Pro';
  font-size: 24px;
  margin: 0;
  padding-top: 5px;
}

.act1-chapter3-the-numbers .set-makeup-graphic .set-makeup-text p {
  font-family: 'Acumin Pro Light';
  font-size: 20px;
  margin: 0;
  opacity: .4;
  height: 24px;
  padding-top: 10px;
  display: flex;
  justify-content: baseline;
  transition: opacity 0.5s ease-in-out;
}

.act1-chapter3-the-numbers .set-makeup-graphic .set-makeup-text p:hover {
  opacity: 1;
  cursor: pointer;
}

/* Act 2 Heading */
.act2-title-section {
  border-top: 6px solid #232136;
  border-bottom: 1px solid #2C2A41;
}

.act2-title-section .section-wrapper {
  max-width: 90rem;
  margin: auto;
  margin-top: 70px;
  padding: 50px 50px;
}

.act2-title-section .section-wrapper h1 {
  font-family: 'Emirates';
  font-size: 36px;
  color: white;
  margin: 0;
}

.act2-title-section .section-wrapper p {
  font-family: 'Acumin Pro Light';
  font-size: 20px;
  letter-spacing: -0.2px;
  margin-top: 12px;
}

/* Act 2, Chapter 1 Introduction */
.act2-chapter1 {
  max-width: 90rem;
  margin: auto;
  display: flex;
  padding: 80px 50px;
}

.act2-chapter1 .titles {
  min-width: 40%;
}

.act2-chapter1 .titles h2 {
  font-family: 'Acumin Pro Light';
  font-size: 18px;
  letter-spacing: 1.2px;
  color: #2E2E2E;
  margin-top: 23px;
}

.act2-chapter1 .titles h3 {
  font-family: 'Acumin Pro';
  font-size: 24px;
  letter-spacing: -0.3px;
  line-height: 20px;
  color: #2C2C2C;
  margin: 0;
}

/* Act 2, Chapter 1 Competitive Audit Kahoot */
.act2-chapter1-audit {
  background-color: #22212E;
}

.act2-chapter1-audit .audit-wrapper {
  max-width: 90rem;
  margin: auto;
  padding: 80px 50px;
}

.act2-chapter1-audit .titles p {
  font-family: 'Acumin Pro Light';
  font-size: 20px;
  letter-spacing: -0.2px;
}

.act2-chapter1-audit .interactive-content .visual.shifted img {
  height: 450px;
  translate: -25px 0px;
  padding-top: 50px;
}

.act2-chapter1-audit .interactive-content .visual img {
  height: 450px;
  padding-top: 50px;
}

.act2-chapter1-audit .interactive-content {
  display: flex;
  justify-content: space-between;
}

.act2-chapter1-audit .content-text {
  max-width: 600px;
  padding-top: 55px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.act2-chapter1-audit .content-heading .kahoot-branding {
  display: flex;
}

.act2-chapter1-audit .content-heading .kahoot-branding img {
  width: 20px;
  height: 20px;
  translate: 0px 4px;
}

.act2-chapter1-audit .content-heading .kahoot-branding h2 {
  margin: 0;
  padding-left: 10px;
  font-family: 'Acumin Pro Light';
  font-size: 20px;
  letter-spacing: 1.2px;
  translate: 0px 5px;
  opacity: 0.6;
}

.act2-chapter1-audit .option-picker h1 {
  font-family: 'Emirates';
  font-size: 30px;
  color: white;
  margin: 0;
}

.act2-chapter1-audit .option-picker {
  display: flex;
  padding-top: 12px;
}

.act2-chapter1-audit .option-picker .unfocused {
  opacity: 0.4;
  cursor: pointer;
  transition: opacity 0.5s ease-in-out;
}

.act2-chapter1-audit .option-picker .unfocused:hover {
  opacity: 0.7;
}

/* Act 2, Chapter 1 Considerations */
.act2-chapter1-considerations {
  border-bottom: 1px solid #2C2A41;
}

.act2-chapter1-considerations .content-wrapper {
  max-width: 90rem;
  padding: 80px 50px;
  margin: auto;
}

.act2-chapter1-considerations .titles h1 {
  font-family: 'Emirates';
  font-size: 20px;
  color: white;
  margin: 0;
}

.act2-chapter1-considerations .titles p {
  font-family: 'Acumin Pro Light';
  font-size: 20px;
  letter-spacing: -0.2px;
  margin: 0;
}

.act2-chapter1-considerations .obstacles {
  display: flex;
  justify-content: space-between;
  padding-top: 70px;
}

.act2-chapter1-considerations .obstacle h2 {
  font-family: 'Acumin Pro Light';
  font-size: 18px;
  letter-spacing: 1.2px;
  color: #2E2E2E;
  line-height: 0px;
}

.act2-chapter1-considerations .obstacle h3 {
  font-family: 'Acumin Pro';
  font-size: 24px;
  letter-spacing: -0.3px;
  color: #2C2C2C;
  line-height: 28px;
  padding-bottom: 30px;
}

/* Act 2, Chapter 1 Introduction */
.act2-chapter2 {
  max-width: 90rem;
  margin: auto;
  padding: 80px 50px;
  display: flex;
  justify-content: space-between;
}

.act2-chapter2 .text {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.act2-chapter2 .plan-previews img {
  height: 430px;
  padding-right: 15px;
}

.act2-chapter2 .text {
  max-width: 600px;
}

.act2-chapter2 .text h2 {
  font-family: 'Acumin Pro Light';
  font-size: 18px;
  letter-spacing: 1.2px;
  color: #2E2E2E;
  line-height: 0px;  
}

.act2-chapter2 .text h3 {
  font-family: 'Acumin Pro';
  font-size: 24px;
  letter-spacing: -0.3px;
  color: #2C2C2C;
  line-height: 28px;
  margin: 0;
  padding-bottom: 30px;
}

/* Act 2, Chapter 2 Wireframes */
.act2-chapter2-wireframes {
  background-color: #12111C;
}

.act2-chapter2-wireframes .content-wrapper {
  max-width: 90rem;
  margin: auto;
  padding: 80px 50px;
}

.act2-chapter2-wireframes .titles {
  margin-bottom: 100px;
}

.act2-chapter2-wireframes .titles h1 {
  font-family: 'Emirates';
  font-size: 20px;
  color: white;
  margin-bottom: -10px;
}

.act2-chapter2-wireframes .titles p {
  font-family: 'Acumin Pro Light';
  font-size: 20px;
  color: white;
  opacity: 0.8;
}

.act2-chapter2-wireframes .interactive-content {
  display: flex;
  flex-direction: row;
}

.act2-chapter2-wireframes .thumbnails img {
  height: 100px;
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
}

.act2-chapter2-wireframes .thumbnails .unfocused {
  opacity: 0.4;
}

.act2-chapter2-wireframes .slide-content img {
  height: 600px;
  margin-bottom: 50px;
}

.act2-chapter2-wireframes .annotations p,
.act2-chapter2-wireframes .annotations h2 {
  padding-left: 25px;
  color: white;
  width: 90%;
}

.act2-chapter2-wireframes .annotations h2 {
  margin: 0;
  font-family: 'Acumin Pro Light';
  font-size: 18px;
  letter-spacing: 1.2px;
  color: white;
  padding-left: 25px;
  margin-bottom: 20px;
}

.act2-chapter2-wireframes .annotations .annotation .line {
  width: 15px;
  height: 1px;
  background-color: #979797;
  translate: 0px 25px;
  margin-right: 0px;
}

/* Act 2, Chapter 2 Buzzer Design */
.act2-chapter2-buzzer {
  max-width: 90rem;
  margin: auto;
  padding: 80px 50px;
}

.act2-chapter2-buzzer .titles h1 {
  font-family: 'Emirates';
  font-size: 20px;
  color: white;
  margin: 0;
}

.act2-chapter2-buzzer .titles p {
  font-family: 'Acumin Pro Light';
  font-size: 20px;
  letter-spacing: -0.2px;
  margin: 0;
  padding-top: 10px;
}

.act2-chapter2-buzzer .selection-panels {
  background-color: #1E1C34;
  width: 600px;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  border: 1px solid #322F53;
}

.act2-chapter2-buzzer .interactive-view {
  padding-top: 60px;
  display: flex;
  flex-direction: row;
}

.act2-chapter2-buzzer .panel {
  display: flex;
  padding-bottom: 30px;
  padding-top: 30px;
  border-bottom: 1px solid #322F53;
}

.act2-chapter2-buzzer .panel.unfocused img,
.act2-chapter2-buzzer .panel-last.unfocused img {
  opacity: 0.4;
  transition: opacity 0.5s ease-in-out;
}

.act2-chapter2-buzzer .panel.unfocused p,
.act2-chapter2-buzzer .panel-last.unfocused p {
  opacity: 0.4;
  transition: opacity 0.5s ease-in-out;
}

.act2-chapter2-buzzer .panel.unfocused:hover p,
.act2-chapter2-buzzer .panel-last.unfocused:hover p,
.act2-chapter2-buzzer .panel.unfocused:hover img,
.act2-chapter2-buzzer .panel-last.unfocused:hover img {
  cursor: pointer;
  opacity: 0.7;
}

.act2-chapter2-buzzer .panel-last {
  padding-top: 30px;
  display: flex;
}

.act2-chapter2-buzzer .panel img,
.act2-chapter2-buzzer .panel-last img {
  padding: 0px 20px;
}

.act2-chapter2-buzzer .panel-label p {
  margin: 0;
}

.act2-chapter2-buzzer .panel-label .p1 {
  font-family: 'Acumin Pro';
  font-size: 20px;
  opacity: 1;
}

.act2-chapter2-buzzer .annotated-buzzer img {
  height: 650px;
  padding-left: 30px;
  margin: 0;
}

.act2-chapter2-buzzer .annotations {
  min-width: 30px;
  max-width: 400px;
  padding-left: 10px;
}

/* Act 2, Chapter 3 Introduction */
.act2-chapter3 {
  border-top: 1px solid #2C2A41;
}

.act2-chapter3 .content-wrapper {
  max-width: 90rem;
  margin: auto;
  padding: 80px 50px;
  display: flex;
}

.act2-chapter3 .titles {
  min-width: 40%;
  margin-top: 5px;
}

.act2-chapter3 .titles h2 {
  font-family: 'Acumin Pro Light';
  font-size: 18px;
  letter-spacing: 1.2px;
  color: #2E2E2E;
  margin: 0;
  padding-bottom: 12px;
}

.act2-chapter3 .titles h3 {
  font-family: 'Acumin Pro';
  font-size: 24px;
  letter-spacing: -0.3px;
  color: #2C2C2C;
  line-height: 20px;
  margin: 0;
}

.act2-chapter3 p {
  margin: 0;
}

/* Act 2, Chapter 3 Slideshow */
.act2-chapter3-slideshow {
  background-color: #12111C;  
}

.act2-chapter3-slideshow .slideshow-wrapper {
  max-width: 90rem;
  margin: auto;
  padding: 80px 50px;
}

.act2-chapter3-slideshow .slideshow-wrapper .titles {
  text-align: center;
}

.act2-chapter3-slideshow .slideshow-wrapper .titles h1 {
  font-family: 'Emirates';
  font-size: 20px;
  color: white;
  margin-bottom: -10px;
}

.act2-chapter3-slideshow .slideshow-wrapper .titles p {
  font-family: 'Acumin Pro Light';
  font-size: 20px;
  color: white;
  opacity: 0.8;
}

.act2-chapter3-slideshow .slide-content {
  padding: 100px 0px;
}

.act2-chapter3-slideshow .slide-content,
.act2-chapter3-slideshow .slide-content .images {
  display: flex;
}

.act2-chapter3-slideshow .slide-content .images img {
  height: 459px;
}

.act2-chapter3-slideshow .slide-content .annotations {
  padding-left: 25px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px 40px;
}

.act2-chapter3-slideshow .slide-content .annotations h2 {
  font-family: 'Acumin Pro Light';
  font-size: 14px;
  letter-spacing: 1.2px;
  color: #B2B2B2;
  margin: 0;
  padding-bottom: 10px;
}

.act2-chapter3-slideshow .slide-content .annotations p {
  color: white;
  margin: 0;
}

.act2-chapter3-slideshow .slide-content .annotations .line {
  width: 100%;
  height: 1px;
  background-color: white;
  opacity: 0.2;
}

.act2-chapter3-slideshow .slideshow-controls {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #12111C;
  padding-bottom: 80px;
}

.act2-chapter3-slideshow .slideshow-controls p {
  color: white;
}

.act2-chapter3-slideshow .previous-arrow,
.act2-chapter3-slideshow .next-arrow {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 35%;
}

.act2-chapter3-slideshow .previous-arrow {
  justify-content: flex-end;
  text-align: right;
}

.act2-chapter3-slideshow .previous-arrow img {
  width: 60px;
  padding-left: 20px;
}

.act2-chapter3-slideshow .next-arrow img {
  width: 60px;
  padding-right: 20px;
}

.act2-chapter3-slideshow .arrow-labels .p1 {
  font-family: 'Acumin Pro Light';
}

.act2-chapter3-slideshow .arrow-labels p {
  opacity: .4;
  font-size: 20px;
  margin: 0;
  transition: opacity 0.5s ease-in-out;
}

.act2-chapter3-slideshow .previous-arrow .stacked-arrow-buttons {
  position: relative;
  translate: 0px -30px;
}

.act2-chapter3-slideshow .next-arrow .stacked-arrow-buttons {
  translate: -80px -30px;
}

.act2-chapter3-slideshow .previous-arrow .stacked-arrow-buttons img,
.act2-chapter3-slideshow .next-arrow .stacked-arrow-buttons img {
  position: absolute;
  top: 0;
  left: 0;
  transition: opacity 0.5s ease-in-out;
}

.act2-chapter3-slideshow .previous-arrow .stacked-arrow-buttons img:last-child,
.act2-chapter3-slideshow .next-arrow .stacked-arrow-buttons img:last-child {
  opacity: 0;
}

.act2-chapter3-slideshow .previous-arrow:hover img:first-child,
.act2-chapter3-slideshow .next-arrow:hover img:first-child {
  opacity: 0;
  cursor: pointer;
}

.act2-chapter3-slideshow .previous-arrow:hover .arrow-labels p,
.act2-chapter3-slideshow .next-arrow:hover .arrow-labels p {
  opacity: 1;
  cursor: pointer;
}

.act2-chapter3-slideshow .previous-arrow:hover img:last-child,
.act2-chapter3-slideshow .next-arrow:hover img:last-child {
  opacity: 1;
  cursor: pointer;
}

.act2-chapter3-slideshow .current-slide-labels p {
  text-align: center;
  font-size: 20px;
  margin: 0;
}

.trivio .reflections {
  max-width: 90rem;
  margin: auto;
  padding: 80px 50px;
  display: flex;
  padding-bottom: 180px;
}

.trivio .reflections h2 {
  min-width: 40%;  
}

/* Transitions */
.image-fade-enter, .image-fade-exit-active {
  opacity: 0;
  transform: translateY(-4px);
  transition: opacity 30ms ease-in, transform 30ms ease-in;;
}

.image-fade-enter-active, .image-fade-exit {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 200ms ease-in, transform 200ms ease-in;
}

.annotation-fade-enter, .annotation-fade-exit-active {
  opacity: 0;
  transform: translateY(10px);
  transition: opacity 0ms ease-out, transform 0ms ease-out;
}

.annotation-fade-enter-active, .annotation-fade-exit {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 800ms ease, transform 500ms ease;
}